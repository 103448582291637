import React from "react"

import "./styles.scss"
import PhoneNumbersView from "../phoneNumbersView"

import Grid from "@material-ui/core/Grid"

const footer = ({ email, UNP }) => (
  <>
    <div id="footerId"></div>
    <div className="footer">
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction="row" justify="center">
            <div className="footer-title">
              Выкупаем авто за 15 минут. Звоните!
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container direction="row" justify="center">
                <div className="footer-email">
                  <i className="fas fa-at"></i>
                  <a href={`mailto:${email}`}>{email}</a>
                </div>
              </Grid>
              <Grid container direction="row" justify="center">
                <div className="footer-phone">
                  <PhoneNumbersView />
                </div>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Grid container direction="row" justify="flex-end">
                <span className="footer-ip">{UNP}</span>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Grid container direction="row" justify="flex-end">
                <div className="sign">
                  <div className="sign__container">
                    {/* <a href="https://swegroup.web.app/" target="_blank">
                      <span className="sign__label">Разработчик</span>{" "}
                      <span className="sign__logo">SWEG</span>
                    </a> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  </>
)

footer.displayName = "footer"
export default footer
