import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles(() => ({
  firstPhoneNumberContainer: {
    textAlign: "start",
  },
}))

const onClickFactory = target => event => {
  event.preventDefault()
  window.ym && window.ym(62270920, "reachGoal", target)
  const href = event.currentTarget.href
  setTimeout(() => (window.location.href = href), 0)
}

const onPhoneClick = onClickFactory("phone-click")
const onTelegramClick = onClickFactory("telegram-click")
const onViberClick = onClickFactory("viber-click")

const PhoneNumbersView = React.memo(
  ({ classForPhones = [], isMessengerVisible = false }) => {
    const {
      site: {
        siteMetadata: { phoneNumbers, socialMediaNumber },
      },
    } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              phoneNumbers {
                phone
                pure
              }
              socialMediaNumber {
                telegram
                viber
              }
            }
          }
        }
      `
    )

    const classes = useStyles()

    return (
      <Grid container direction="column">
        <Grid item className={classes.firstPhoneNumberContainer}>
          <a
            className={classForPhones[0]}
            onClick={onPhoneClick}
            href={`tel:${phoneNumbers[0].pure}`}
          >
            {phoneNumbers[0].phone}
          </a>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <a
                className={classForPhones[1]}
                href={`tel:${phoneNumbers[1].pure}`}
                onClick={onPhoneClick}
              >
                {phoneNumbers[1].phone}
              </a>
            </Grid>
            {isMessengerVisible && (
              <Grid item>
                <i className="fas"></i>
                <a
                  href={`tg://resolve?domain=${socialMediaNumber.telegram}`}
                  onClick={onTelegramClick}
                >
                  <i
                    title="приложение Telegram должно быть установлено"
                    className="telegram-icon fab fa-telegram"
                  ></i>
                </a>
                <i className="fas"></i>
                <a
                  href={`viber://add?number=${socialMediaNumber.viber}`}
                  onClick={onViberClick}
                >
                  <i
                    title="приложение Viber должно быть установлено"
                    className="viber-icon fab fa-viber"
                  ></i>
                </a>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }
)
PhoneNumbersView.displayName = "PhoneNumbersView"
export default PhoneNumbersView
